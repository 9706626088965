import React, { ReactElement, useState } from 'react';

import {
  // Text,
  Grid,
  GridItem,
  HStack,
  VStack,
  Button,
  Image,
  Spacer,
  Link,
} from '@chakra-ui/react';

import { UnAuthHeaderStyles } from './styles';
import logoImg from '@assets/images/lvedLogoWhite.svg';
import closeMenuImgWhite from '@assets/images/closeMenuWhite.svg';
import hamburgerMenuImgWhite from '@assets/images/hamburgerMenuWhite.svg';
import MenuLink from '../menu-link/MenuLink';
import { IUnAuthHeaderProps } from './interfaces';
import { useNavigate } from 'react-router';

export default function UnAuthHeader({ activeLink }: IUnAuthHeaderProps): ReactElement {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const closeMenu = () => {
    setExpanded(false);
  };
  return (
    <Grid {...UnAuthHeaderStyles.gridMaster}>
      <GridItem {...UnAuthHeaderStyles.gridItemVerticalNavigation}>
        {expanded && (
          <VStack {...UnAuthHeaderStyles.verticalOpenContainer}>
            <HStack spacing='24px' width='100%'>
              <Image
                {...UnAuthHeaderStyles.hamburgerMenuImage}
                src={closeMenuImgWhite}
                onClick={() => setExpanded(!expanded)}
              />
              <Link href='https://www.lved.com/'>
                <Image {...UnAuthHeaderStyles.logoImage} src={logoImg} />
              </Link>
              <Spacer />
            </HStack>
            <MenuLink
              title='Home'
              isActiveLink={activeLink === 'Home'}
              url='http://www.lved.com'
              isUrlLocal={false}
              closeMenu={closeMenu}
            />
            <MenuLink
              title='Blog'
              isActiveLink={activeLink === 'Blog'}
              url='/articles'
              isUrlLocal
              closeMenu={closeMenu}
            />
            <MenuLink
              title='FAQ'
              isActiveLink={activeLink === 'FAQ'}
              url='/faq'
              isUrlLocal
              closeMenu={closeMenu}
            />
            <MenuLink
              title='Prices'
              isActiveLink={activeLink === 'Prices'}
              url='http://www.lved.com/prices.html'
              isUrlLocal={false}
              closeMenu={closeMenu}
            />
            <MenuLink
              title='Affiliates'
              isActiveLink={activeLink === 'Affiliates'}
              url='http://www.lved.com/affiliates.html'
              isUrlLocal={false}
              closeMenu={closeMenu}
            />
            <MenuLink
              title='Advisors'
              isActiveLink={activeLink === 'Advisors'}
              url='https://www.lved.com/advisors.html'
              isUrlLocal={false}
              closeMenu={closeMenu}
            />
            <MenuLink
              title='About us'
              isActiveLink={activeLink === 'About us'}
              url='http://www.lved.com/about-us.html'
              isUrlLocal={false}
              closeMenu={closeMenu}
            />
            <MenuLink
              title='Contact us'
              isActiveLink={activeLink === 'Contact us'}
              url='http://www.lved.com/contact-us.html'
              isUrlLocal={false}
              closeMenu={closeMenu}
            />
            <Spacer />
            <Button
              {...UnAuthHeaderStyles.button}
              width='100%'
              onClick={() => {
                navigate('/signup');
              }}
            >
              Get Started
            </Button>
            <Button
              {...UnAuthHeaderStyles.buttonNoFill}
              width='100%'
              onClick={() => {
                navigate('/signin');
              }}
            >
              Sign in
            </Button>
          </VStack>
        )}
        {!expanded && (
          <HStack {...UnAuthHeaderStyles.verticalClosedContainer}>
            <HStack spacing='24px'>
              <Image
                {...UnAuthHeaderStyles.hamburgerMenuImage}
                src={hamburgerMenuImgWhite}
                onClick={() => setExpanded(!expanded)}
              />
              <Link href='https://www.lved.com/'>
                <Image boxSize='80px' src={logoImg} />
              </Link>
            </HStack>
            <Spacer />
            <Button
              {...UnAuthHeaderStyles.button}
              onClick={() => {
                navigate('/signup');
              }}
            >
              Get Started
            </Button>
          </HStack>
        )}
      </GridItem>
      <GridItem {...UnAuthHeaderStyles.gridItemHorizontalNavigation}>
        <HStack {...UnAuthHeaderStyles.verticalClosedContainer}>
          <HStack p='10px 0px 10px 0px' spacing='24px'>
            <Link href='https://www.lved.com/'>
              <Image {...UnAuthHeaderStyles.logoImage} src={logoImg} />
            </Link>
          </HStack>
          <Spacer />
          <MenuLink
            title='Home'
            isActiveLink={activeLink === 'Home'}
            url='http://www.lved.com'
            isUrlLocal={false}
          />
          <MenuLink title='Blog' isActiveLink={activeLink === 'Blog'} url='/articles' isUrlLocal />
          <MenuLink title='FAQ' isActiveLink={activeLink === 'FAQ'} url='/faq' isUrlLocal />
          <MenuLink
            title='Prices'
            isActiveLink={activeLink === 'Prices'}
            url='http://www.lved.com/prices.html'
            isUrlLocal={false}
          />
          <MenuLink
            title='Affiliates'
            isActiveLink={activeLink === 'Affiliates'}
            url='http://www.lved.com/affiliates.html'
            isUrlLocal={false}
          />
          <MenuLink
            title='Advisors'
            isActiveLink={activeLink === 'Advisors'}
            url='http://www.lved.com/advisors.html'
            isUrlLocal={false}
          />
          <MenuLink
            title='About us'
            isActiveLink={activeLink === 'About us'}
            url='http://www.lved.com/about-us.html'
            isUrlLocal={false}
          />
          <MenuLink
            title='Contact us'
            isActiveLink={activeLink === 'Contact us'}
            url='http://www.lved.com/contact-us.html'
            isUrlLocal={false}
          />
          <Button
            {...UnAuthHeaderStyles.buttonNoFill}
            onClick={() => {
              navigate('/signin');
            }}
          >
            Sign in
          </Button>
          <Button
            {...UnAuthHeaderStyles.button}
            onClick={() => {
              navigate('/signup');
            }}
          >
            Get Started
          </Button>
        </HStack>
      </GridItem>
    </Grid>
  );
}
